.top-list{
    padding: 0px;
    padding-top: 10px;
    margin: 0px;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li{
        width: 50%;
        width: calc(50% - 2.5px);
        margin-right: 5px;
        a{
            font-size: rem-calc(14);
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background: rgba(0,0,0,.6);
            // background-color: transparent;
            color: blue;
            width: 100%;
            text-align: center;
            display: inline-block;
            border-radius: 5px;
            padding: 5px 3px;
            border: 1px dotted yellow;
            &:hover{
                // background-color: $color-3st;
                color: #fff;
            }
        }
        &:nth-child(2n + 2){
            margin-right: 0px;
        }
    }
}
.group-pro-main{
    margin-top: 5px;
    border-radius: 15px;
    background-color: #000;
}
.pro-main--item {
    position: relative;
    overflow: hidden;
    // background: rgba(255,255,255,.2);
    // background: rgba(255,255,255,.2);
    z-index: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    width: 50%;
    width: calc(50% - 2.5px);
    border-radius: 5px;
    // .tip__new {
    //     position: absolute;
    //     background-color: $color-4st;
    //     color: #fff;
    //     display: inline-block;
    //     top: 15px;
    //     left: 15px;
    //     font-size: 12px;
    //     border-radius: 100%;
    //     text-transform: capitalize;
    //     width: 40px;
    //     height: 40px;
    //     line-height: 40px;
    //     text-align: center;
    //     z-index: 9;
    //     box-shadow: 0 5px 10px 0 rgba(0,0,0,.12);
    // }
    // .sale {
    //     position: absolute;
    //     color: #fff;
    //     background-color: $color-3st;
    //     display: inline-block;
    //     padding: 3px 10px;
    //     top: 15px;
    //     right: 5px;
    //     font-size: 13px;
    //     border-radius: 2px;
    //     text-transform: capitalize;
    //     z-index: 9;
    // }
    .pm-item__img{
        overflow: hidden;
        position: relative;
        .code{
            position: absolute;
            content: "";
            right: 0px;
            bottom: 10px;
            background-color: rgba(255,0,0,.9);
            color: #fff;
            font-size: rem-calc(16);
            // border-bottom: none;
            // border-right: none;
            border-top-left-radius: 15px;
            border-bottom-right-radius: 15px;
            padding: 2px 10px;
            // border-radius: 15px;
            z-index: 9;
            font-weight: $font-bold;
        }
        img{
            display: block;
            width: 100%;
            max-width: 100%;
            // border-top-left-radius: 5px;
            // border-top-right-radius: 5px;
            border-radius: 15px;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
        }
    }
    .pm-item__cap{
        padding: 5px 5px;
        background-color: #fff;
        // background: rgb(255,255,255);
        // background: linear-gradient(45deg, rgba(255,255,255,.7) 0%, rgba(0,0,0,1) 100%);
        .danhmuc{
            display: inline-table;
            width: 100%;
            padding: 2px 0px;
            color: $color-3st;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: rem-calc(10);
        }
        @include headings{
            padding: 0px;
            font-weight: 700;
            line-height: 14px;
            a{
                color: $color-2st;
                font-size: rem-calc(16);
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .item-cap__desc{
            .detail-pro__price{
                font-size: rem-calc(14);
                font-weight: 400;
                color: $color-4st;
                small{
                    margin-left: 5px;
                    text-decoration: line-through;
                    font-size: 12px;
                    color: #888;
                    font-weight: 400;
                }
            }
        }
        .info{
            padding: 0px;
            margin: 0px;
            list-style: none;
            font-size: rem-calc(12);
            li{
                display: inline-table;
            }
        }
    }
    &:hover{
        img{
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
    &:nth-child(2n + 2){
        margin-right: 0px;
    }
}
@media(min-width: 992px){
    .pro-ly-4{
        .pro-main--item{
            width: 25%;
            width: calc(25% - 11.25px);
            margin-right: 15px;
            margin-bottom: 15px;
            &:nth-child(4n + 4){
                margin-right: 0px;
            }
        }
    }
}
// @media(min-width: 1200px){
    
// }