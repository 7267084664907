//Custom variables

// Descriptive color variables

// Functional color variables
$color-1st : #000;

//BLUE
$color-2st: blue;

//green
$color-3st : green;

//red
$color-4st : #f00;

// Font stacks
$font-1st : 'font-family: 'Roboto', sans-serif;';

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-supperbold: 900;

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';
