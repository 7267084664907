.tienich{
	list-style: none;
	margin: 0px;
	padding: 0px;
	z-index: 999;
	position: fixed;
	right: 5px;
	bottom: 15px;
	width: 35px;
	li{
		margin-bottom: 5px;
		a{
			display: inline-block;
			text-align: center;
			width: 100%;
			border-radius: 5px;
			background-color: #fff;
			box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
			&.tel{
				background-color: green;
				height: 35px;
				font-size: rem-calc(30px);
				color: #fff;
				line-height: 35px;
			}
		}
	}
}

// .back-to-top{
// 	content: "";
// 	position: fixed;
// 	right: 15px;
// 	bottom: 50px;
//     width: 45px;
//     height: 45px;
//     background-color: transparent;
//     border-radius: 100%;
//     text-align: center;
//     line-height: 41px;
//     display: inline-table;
//     border: 2px solid $color-3st;
//     i{
//     	color: $color-3st;
//     	font-size: rem-calc(26);
//     }
// }
// @media(max-width: 767px){
// 	.tienich{
// 		width: 100%;
// 		// background-color: $color-4st;
// 		bottom: 0px;
// 		left: 0px;
// 		display: flex;
// 		flex-wrap: nowrap;
// 		li{
// 			width: 50%;
// 			text-align: center;
// 			margin-bottom: 0px;
// 			a{
// 				box-shadow: none;
// 				padding: 0px;
// 				display: flex;
// 				padding: 5px 0px;
// 			    justify-content: center;
// 			    align-items: center;
// 				&.zalo{background-color: transparent;}
// 				&.face{background-color: transparent;}
// 				&.tel{border-radius: 0px;}
// 				span{
// 					display: inline-block !important;
// 					// width: 100%;
// 					img{
// 						height: 30px;
// 						margin-right:5px;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.back-to-top{
// 		bottom: 80px;
// 	}
// }
